<template>
  <div>
    <v-row>
      <v-col cols="12" sm="3">
        <p class="font-weight-medium text-lg-h6 text--secondary">Дані термінала</p>
        <div>
          <span>Адреса:</span>
          <span class="font-weight-medium ml-2">
            {{ servicePoint.address.country }}, {{ servicePoint.address.city }}, {{ servicePoint.address.street }},
            {{ servicePoint.address.building_number }}
          </span>
        </div>
        <div class="mt-2">
          <span>Розташування:</span>
          <v-select
            v-if="editCard"
            class="mt-2"
            v-model="editedTerminal.location"
            :items="servicePointLocation"
            label="Місце розташування"
            outlined
            dense
            hide-details
          ></v-select>
          <span v-else class="font-weight-medium ml-2">{{ editedTerminal.location }}</span>
        </div>
        <div class="mt-2">
          <v-switch
            v-model="editedTerminal.is_searchable"
            class="mt-0"
            :label="editCard ? 'Відображати в мобільному додатку' : 'Відображається в мобільному додатку'"
            color="success"
            :disabled="!editCard"
            hide-details
          ></v-switch>
        </div>
        <div class="mt-5">
          <h3 class="mb-1">Основні дані</h3>
          <div>
            <span>ID:</span>
            <router-link
              :to="{ name: 'TerminalCard', params: { id: currentCompanyId, terminalId } }"
              class="font-weight-medium text-decoration-none ml-2 d-inline-block"
            >
              {{ terminalId }}
            </router-link>
          </div>
          <div>
            <span>IMEI:</span>
            <span class="font-weight-medium ml-2 d-inline-block">{{ editedTerminal.imei }}</span>
          </div>
          <div>
            <span>Виробник:</span>
            <span class="font-weight-medium ml-2 d-inline-block">{{ editedTerminal.producer }}</span>
          </div>
          <div>
            <span>Модель:</span>
            <span class="font-weight-medium ml-2 d-inline-block">{{ editedTerminal.model }}</span>
          </div>
          <div>
            <span>Ємність купюроприймача:</span>
            <span class="font-weight-medium ml-2 d-inline-block">{{ editedTerminal.bill_acceptor_capacity }}</span>
          </div>
          <div>
            <span class="mr-1">Канал радіомодуля:</span>
            <span class="font-weight-medium">{{ terminalData.info.channel }}</span>
          </div>
          <div>
            <span class="mr-1">Версія модуля радіозв'язку:</span>
            <span class="font-weight-medium">{{ terminalData.info.radio }}</span>
          </div>
        </div>
        <div class="mt-5">
          <h3 class="mb-1">Зв`язок</h3>
          <div>
            <span class="mr-1">Тип зв`язку:</span>
            <span class="font-weight-medium">{{ terminalData.info.network_type }}</span>
          </div>
          <div>
            <span class="mr-1">Телефон модема:</span>
            <span class="font-weight-medium">{{ terminalData.info.modem_phone_num }}</span>
          </div>
          <div>
            <span class="mr-1">Рівень звязку:</span>
            <span class="font-weight-medium">{{ terminalData.status.rssi }}</span>
          </div>
        </div>
      </v-col>
      <DevicesTable :terminal-id="terminalId" @disconnected="$emit('disconnected')" />
    </v-row>

    <v-container fluid>
      <div class="d-flex justify-end">
        <v-btn v-if="!editCard" @click="editCard = true" class="mr-2" small>Редагувати</v-btn>
        <v-btn v-if="editCard" @click="editCard = false" class="mr-2" small>Повернутись</v-btn>
        <v-btn v-show="editCard" @click="showModal = true" color="primary" small>Зберегти</v-btn>
      </div>
    </v-container>

    <ConfirmActionModal @closeModal="showRemoveTerminalModal = false" :showModal="showRemoveTerminalModal">
      <template v-slot:title>Від`єднати термінал?</template>
      <template v-slot:text>Дані будуть оновлені</template>
      <template v-slot:buttons>
        <v-btn @click="disconnectTerminalFromSP" color="primary" text>Так</v-btn>
        <v-btn @click="showRemoveTerminalModal = false" text>Нi</v-btn>
      </template>
    </ConfirmActionModal>

    <ConfirmActionModal @closeModal="showModal = false" :showModal="showModal">
      <template v-slot:title>Зберегти зміни?</template>
      <template v-slot:text>Дані будуть оновлені</template>
      <template v-slot:buttons>
        <v-btn @click="saveTerminal" color="primary" text>Так</v-btn>
        <v-btn @click="showModal = false" text>Нi</v-btn>
      </template>
    </ConfirmActionModal>
  </div>
</template>

<script>
import { ROUTER_LINKS } from '@/const/router-links.enum'
import { TERMINAL_SCHEMA } from '@/const/apiSchemas'
import { mapActions, mapState } from 'vuex'
import convertMachinesTypes from '@/mixins/convertDevicesTypes'
import { mergeObjects } from '@/helpers/mergeObjects'

export default {
  components: {
    DevicesTable: () => import('@/components/common/DevicesTable'),
    ConfirmActionModal: () => import('@/components/dialogs/ConfirmActionModal'),
  },

  mixins: [convertMachinesTypes],
  emits: ['disconnected'],

  props: {
    terminalId: {
      type: Number,
      required: true,
    },
    servicePoint: {
      type: Object,
      required: true,
      default: () => {},
    },
    servicePointLocation: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data: () => ({
    ROUTER_LINKS: ROUTER_LINKS,
    tableHeader: ['ID', 'Номер', 'Модель', 'Відкріпити', 'Відправити команду'],
    loadedTerminal: {},
    isLoaded: false,
    editCard: false,
    showModal: false,
    showRemoveTerminalModal: false,
    editedTerminal: { ...TERMINAL_SCHEMA },
  }),

  computed: {
    ...mapState('terminals', ['terminal']),
    ...mapState('dictionaries', ['machinesTypesDictionary']),
    currentCompanyId() {
      return this.$route.params.id
    },
    terminalData() {
      return {
        info: {
          channel: this.terminal?.state?.info?.channel || 'N/A',
          radio: this.terminal?.state?.info?.radio || 'N/A',
          network_type: this.terminal?.state?.info?.network_type || 'N/A',
          modem_phone_num: this.terminal?.state?.info?.modem_phone_num || 'N/A',
        },
        status: {
          rssi: this.terminal?.state?.status?.rssi || 'N/A',
        },
      }
    },
  },

  mounted() {
    this.initialize()
  },

  methods: {
    ...mapActions('terminals', [
      'loadSelectedTerminal',
      'updateSelectedTerminal',
      'connectDeviceToTerminal',
      'removeDeviceFromTerminal',
    ]),
    ...mapActions('servicePoints', ['removeTerminalFromServicePoint', 'loadSelectedServicePoint']),

    async initialize() {
      const payload = {
        companyId: this.currentCompanyId,
        terminalId: this.terminalId,
      }
      await this.loadSelectedTerminal(payload)
      this.editedTerminal = mergeObjects(this.editedTerminal, this.terminal)
      this.isLoaded = true
    },
    openRemoveDeviceDialog(selectedDeviceId) {
      this.showRemoveDeviceModal = true
      this.selectedDeviceId = selectedDeviceId
    },
    async disconnectTerminalFromSP() {
      this.isLoaded = false
      this.showRemoveTerminalModal = false
      const terminalParams = {
        companyId: this.currentCompanyId,
        servicePointId: this.servicePointId,
        terminalId: this.terminalId,
      }
      await this.removeTerminalFromServicePoint(terminalParams)
      await this.reloadAllServicePoints()
      this.isLoaded = true
    },
    async reloadAllServicePoints() {
      this.isLoaded = false
      const servicePointParams = {
        companyId: this.currentCompanyId,
        servicePointId: this.servicePointId,
      }
      await this.loadSelectedServicePoint(servicePointParams)
      this.isLoaded = true
    },
    async saveTerminal() {
      const payload = {
        companyId: this.currentCompanyId,
        terminalId: this.terminalId,
        updatedTerminal: this.editedTerminal,
      }
      await this.updateSelectedTerminal(payload)
      this.editCard = false
      this.showModal = false
    },
  },
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/table';
.select-wrapper {
  max-width: 450px;
}
.terminal-row {
  background-color: lighten(#cfd8dc, 12%);
}
</style>
